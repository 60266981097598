export const URL_ABOUT = '/about';
export const URL_ADMIN = '/admin';
export const URL_ADMIN_ACCESS_REQUESTS = '/admin/access-requests';
export const URL_ADMIN_ACCESS_ROLE = '/admin/access-role';
export const URL_ADMIN_ACCESS_ROLES = '/admin/access-roles';
export const URL_ADMIN_COMPANY = '/admin/company';
export const URL_ADMIN_COMPANIES = '/admin/companies';
export const URL_ADMIN_MANAGE_ACCESS_REQUEST = '/admin/manage/access-request';
export const URL_ADMIN_MANAGE_SERVICE = '/admin/manage/service';
export const URL_ADMIN_PLATFORM_NEWS = '/admin/platform-news';
export const URL_ADMIN_PLATFORM_RELEASE = '/admin/platform-release';
export const URL_ADMIN_PLATFORM_RELEASE_NEWS = '/admin/platform-release-news';
export const URL_ADMIN_PLATFORM_RELEASE_NEWS_ITEM = '/admin/platform-release-news-item';
export const URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES = '/admin/platform-summary-capabilities';
export const URL_ADMIN_PLATFORM_SUMMARY_CAPABILITY = '/admin/platform-summary-capability';
export const URL_ADMIN_SERVICES = '/admin/services';
export const URL_ADMIN_SERVICES_DATA_TRANSFER = '/admin/services/data-transfer-source';
export const URL_ADMIN_SERVICES_REST = '/admin/services/rest';
export const URL_ADMIN_SERVICES_SFTP = '/admin/services/sftp';
export const URL_ADMIN_SERVICES_STREAMING = '/admin/services/streaming';
export const URL_ADMIN_USER = '/admin/user';
export const URL_ADMIN_USERS = '/admin/users';
export const URL_DASHBOARD_USER = '/dashboard/user';
export const URL_FAQ = '/faq';
export const URL_FEEDBACK = '/feedback';
export const URL_HOME = '/';
export const URL_IVT = '/ivt';
export const URL_NEWS_PLATFORM = '/platform-news';
export const URL_NEWS_SERVICE = '/service-news';
export const URL_PARTNER = '/partner';
export const URL_PROFILE = '/profile';
export const URL_REQUSET_ACCESS = '/request/access';
export const URL_SEARCH = '/search';
export const URL_SERVICE_PROVIDER_METRICS = '/service/provider-metrics';
export const URL_SERVICES_DATA_TRANSFER = '/services/data-transfer-source';
export const URL_SERVICES_REST = '/services/rest';
export const URL_SERVICES_SFTP = '/services/sftp';
export const URL_SERVICES_STREAMING = '/services/streaming';
export const URL_SIGN_IN = '/signIn';
export const URL_SUBSCRIPTIONS = '/subscriptions';
export const URL_USER_GUIDE = '/user-guides';
export const URL_SUPPORT = '/support';
export const URL_WELCOME = '/welcome';
export const URL_403 = '/403-forbidden';

export const USER_TAG = 'USER_TAG';
export const NEWS = 'News';
export const PLATFORM_NEWS = 'Platform News';
export const SERVICE_NEWS = 'Service News';

/**
 * List of menu options on the main menu
 */
export const navigationLinks = [
  {
    name: 'Catalog',
    link: URL_HOME,
    id: 'menu-catalog',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false
  },
  {
    name: 'Dashboard',
    link: URL_DASHBOARD_USER,
    id: 'menu-dashboard',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false
  },
  {
    name: 'Subscriptions',
    link: URL_SUBSCRIPTIONS,
    id: 'menu-subscriptions',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false
  },
  {
    name: 'Registrations',
    link: URL_SERVICES_REST,
    id: 'menu-services',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false
  },
  {
    name: NEWS,
    id: 'menu-news',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false,
    checkNotification: true,
    menu: {
      menuId: 'menu-news-items',
      items: [
        {
          name: PLATFORM_NEWS,
          link: URL_NEWS_PLATFORM,
          id: 'menu-news-platform',
          requiresAuth: true,
          requiresIvt: true,
          requiresAdmin: false,
          checkNotification: true,
        },
        {
          name: SERVICE_NEWS,
          link: URL_NEWS_SERVICE,
          id: 'menu-news-service',
          requiresAuth: true,
          requiresIvt: true,
          requiresAdmin: false,
          checkNotification: true,
        }
      ]
    }
  },
  {
    name: 'Help',
    id: 'menu-help',
    requiresAuth: true,
    requiresIvt: true,
    requiresAdmin: false,
    menu: {
      menuId: 'menu-help-items',
      items: [{
        name: 'FAQ',
        link: URL_FAQ,
        id: 'menu-help-faq',
        requiresAuth: true,
        requiresIvt: true,
        requiresAdmin: false
      }, {
        name: 'User Guides',
        link: URL_USER_GUIDE,
        id: 'menu-help-user-guide',
        requiresAuth: true,
        requiresIvt: true,
        requiresAdmin: false
      }, {
        name: 'Contact Us',
        link: URL_SUPPORT,
        id: 'menu-help-contactus',
        requiresAuth: true,
        requiresIvt: true,
        requiresAdmin: false
      }, {
        name: 'Feedback',
        link: URL_FEEDBACK,
        id: 'menu-help-feedback',
        requiresAuth: true,
        requiresIvt: true,
        requiresAdmin: false
      }]
    }
  },
  {
    name: 'About',
    link: URL_ABOUT,
    id: 'menu-about',
    requiresAuth: false,
    requiresIvt: false,
    requiresAdmin: false
  },
]
export const profileLinks = [
  {
    name: USER_TAG,
    id: 'menu-profile',
    requiresAuth: true,
    requiresIvt: false,
    requiresAdmin: false,
    menu: {
      menuId: 'menu-profile-items',
      items: [
        {
          name: 'Profile',
          link: URL_PROFILE,
          id: 'menu-profile',
          requiresAuth: true,
          requiresIvt: true,
          requiresAdmin: false
        }, {
          name: 'Administration',
          link: URL_ADMIN,
          id: 'menu-admin',
          requiresAuth: true,
          requiresIvt: false,
          requiresAdmin: true
        }, {
          name: 'Logout',
          id: 'menu-logout',
          action: 'logout',
          requiresAuth: true,
          requiresIvt: false,
          requiresAdmin: false
        }
      ]
    }
  }
];


/**
 * Get the service detail page url based on the params
 * @param {number} serviceId
 * @param {number} providerId
 * @returns service detail page url
 */
export const getServiceDetailPageUrl = (serviceId, providerId) => (
  `/service/${serviceId}?providerId=${providerId}`
);
