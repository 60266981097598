import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import CircularProgressIndicator from '../../components/common/CircularProgressIndicator';
import LinkButton from '../../components/common/LinkButton';
import MajorHeading from '../../components/common/MajorHeading';
import { getAdminGroupName, getCurrentAuthenticatedUser,
         isCurrentUserInGroup, isUserAuthenticated
       } from '../../utils/auth';
import { URL_ADMIN_ACCESS_REQUESTS, URL_ADMIN_ACCESS_ROLES, URL_ADMIN_COMPANIES,
         URL_ADMIN_PLATFORM_NEWS, URL_ADMIN_SERVICES_DATA_TRANSFER, 
         URL_ADMIN_SERVICES_REST, URL_ADMIN_SERVICES_SFTP, 
         URL_ADMIN_SERVICES_STREAMING, URL_SERVICE_PROVIDER_METRICS,
         URL_ADMIN_USERS, URL_HOME, URL_SIGN_IN
       } from '../../utils/navigation';

/**
 * This provides the main page for Administration, with buttons to
 * access sub-pages for companies, users, services, etc.  It is expected
 * to be accessed from the Profile menu.
 */
export default function Admin() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState(null);
    const [isAdminUser, setIsAdminUser] = useState(false);

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Administration', href: null }
    ];

    const nonServiceButtons = [
        {
            name: 'Companies',
            id: 'companies',
            path: URL_ADMIN_COMPANIES
        },
        {
            name: 'Users',
            id: 'users',
            path: URL_ADMIN_USERS
        },
        {
            name: 'Access Roles',
            id: 'access-roles',
            path: URL_ADMIN_ACCESS_ROLES
        },
        {
            name: 'Access Requests',
            id: 'access-requests',
            path: URL_ADMIN_ACCESS_REQUESTS
        },
        {
            name: 'Platform News',
            id: 'platform-news',
            path: URL_ADMIN_PLATFORM_NEWS
        },
        {
            name: 'Service Provider Metrics',
            id: 'metrics',
            path: URL_SERVICE_PROVIDER_METRICS
        }
    ];
    const serviceButtons = [
        {
            name: 'Data Transfer',
            id: 'xfr',
            path: URL_ADMIN_SERVICES_DATA_TRANSFER
        },
        {
            name: 'REST Services',
            id: 'rest',
            path: URL_ADMIN_SERVICES_REST
        },
        {
            name: 'SFTP Services',
            id: 'sftp',
            path: URL_ADMIN_SERVICES_SFTP
        },
        {
            name: 'Streaming Services',
            id: 'streaming',
            path: URL_ADMIN_SERVICES_STREAMING
        }
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Administration'));
        isUserAuthenticated()
        .then(value => {
            if (value) {
                getCurrentAuthenticatedUser()
                .then(userInfo => setUserInfo(userInfo));

                isCurrentUserInGroup(getAdminGroupName())
                .then((val) => {
                    setIsAdminUser(val);
                });
            } else {
                navigate(URL_SIGN_IN, { replace: true })
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createButtonRow = (buttons) => (
        <Grid item xs={12}
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Stack direction='row'
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'flex'
                    },
                    mt: {
                        sm: '1rem'
                    }
                }}
            >
                {
                    buttons.map(({ name, id, path }, idx) => (
                        <Grid item key={name}>
                            <LinkButton text={name}
                                href={path}
                                id={id}
                                style={{ margin: '.5rem' }}
                            />
                        </Grid>
                    ))
                }
            </Stack>
        </Grid>
    );

    if (!isAdminUser) {
        return null;
    }

    return (
        <Box id='admin-page'>
            <Card sx={{ minHeight: '10rem' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                    {
                        !userInfo ? 
                            <CircularProgressIndicator 
                                altText='Loading user info...'
                            />
                        : (
                            <Box>
                                <MajorHeading
                                    label={`Hello, ${userInfo ? userInfo.attributes?.given_name ?? userInfo.username ?? '' : ''}!`}
                                />
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>

                                        <Divider variant="middle" sx={{ my: '1rem' }} />
                                    </Grid>
                                    { createButtonRow(nonServiceButtons) }
                                    { createButtonRow(serviceButtons) }
                                </Grid>
                            </Box>
                        )
                    }
                </CardContent>
            </Card>
        </Box >
    );
};
