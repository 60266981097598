import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { Fragment, useCallback, useEffect, useMemo, useRef, useState
       } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createDataTransferSource, createMainService, deleteDataTransferSource,
         deleteService, getCompanyDetail, getDataTransferSource, getServiceDetail,
         updateDataTransferSource, updateMainService
       } from '../../../apiClient';
import { setAlert } from '../../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import { fetchCategories, selectCategories } from '../../../app/slices/search';
import { selectCompanyId, selectCompanyAbbreviation } from '../../../app/slices/user';
import ActionButton from '../../../components/common/ActionButton';
import BodyText from '../../../components/common/BodyText';
import LinkButton from '../../../components/common/LinkButton';
import MajorHeading from '../../../components/common/MajorHeading';
import OkCancelDialog from '../../../components/common/OkCancelDialog';
import ProcessingOverlay from '../../../components/common/ProcessingOverlay';
import DataTransferSource from '../../../components/service-registration/DataTransferSource';
import WizardServiceCore from '../../../components/service-registration/WizardServiceCore';
import { useAbortController } from '../../../hooks';
import { validateDataTransferSource } from '../../../utils/dataTransfer';
import { URL_ADMIN, URL_ADMIN_SERVICES, URL_HOME, URL_SERVICE_PROVIDER_METRICS } from '../../../utils/navigation';
import { createMainServiceRequestBody, getInitialMainServiceState,
         getServicesPage, getServiceVersionRegisterPage,
         mainServiceDetailToRegistrationInput, MANAGE_SERVICE_PAGE_ACTION,
         parseScopeStr, uploadResourceFiles, validateServiceCoreData
       } from '../../../utils/serviceRegistration';
import { checkServiceType, ServiceTypeUtils, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from '../../../utils/utils';
import { isErrorObjectEmpty } from '../../../utils/validation';
import { fetchRoles, selectRolesForService } from '../../../app/slices/company';
import SectionHeader from '../../../components/common/SectionHeader';
import { Box } from '@mui/material';


/**
 * This provides the ability for the user to register a new service, view
 * service parameters, edit service parameters, or delete a service. The
 * create/view/edit/delete action is set using the 'action' argument or the
 * ?action= param in the URL.
 */

const MainServiceRegistration = ({ admin, serviceType, serviceId, action }) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const formRef = useRef(null);

    const categories = useSelector(useCallback(selectCategories, []));
    const companyId = useSelector(useCallback(selectCompanyId, []));
    const companyAbbrev = useSelector(useCallback(
        selectCompanyAbbreviation, []));
    const companyRoles = useSelector(useCallback(selectRolesForService, []));

    const [error, setError] = useState({
        message: '',
        show: false
    });

    const [formErrors, setFormErrors] = useState({
        serviceCoreData: {},
        dataTransferSource: {}
    });

    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [pageAction, setPageAction] = useState(
        action ?? MANAGE_SERVICE_PAGE_ACTION.create);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const deleteDialogRef = useRef(null);
    const [input, setInput] = useState(
        getInitialMainServiceState(serviceType, companyId, companyAbbrev));
    const [dtsId, setDtsId] = useState(null);
    const [serviceName, setServiceName] = useState('');

    const isCreating = useMemo(() =>
        pageAction === MANAGE_SERVICE_PAGE_ACTION.create, [pageAction]);
    const isDeleting = useMemo(() =>
        pageAction === MANAGE_SERVICE_PAGE_ACTION.remove, [pageAction]);
    const isEditing = useMemo(() =>
        pageAction === MANAGE_SERVICE_PAGE_ACTION.edit, [pageAction]);
    const isViewing = useMemo(() =>
        pageAction === MANAGE_SERVICE_PAGE_ACTION.view, [pageAction]);
    const isDts = useMemo(() =>
        checkServiceType(serviceType, ServiceTypeUtils.TYPES.DATA_TRANSFER),
        [serviceType]);


    useEffect(() => {
        window.scrollTo(0, 0);
        initBreadCrumbs(serviceType);

        // Initialize the service data only if the cached categories list is valid
        if (categories && categories.length) {
            initServiceData(categories);
        }

        dispatch(fetchCategories({ abortSignalRef }))
        .unwrap()
        .then((newCategories) => {
            // If newly fetched categories are different from the current ones,
            // reinitialize the service data.
            if (categories && !categories.length &&
                !newCategories.every((value, idx) => value === categories[idx])
            ) {
                initServiceData(newCategories);
            }
        })
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get categories:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get categories',
                severity: 'error'
            }));
        });

        dispatch(fetchRoles({ abortSignalRef }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get roles', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get roles',
                severity: 'error'
            }));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        initPageTitle(serviceType, serviceName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceType, serviceName, pageAction])

    useEffect(() => {
        const localAction = searchParams.get('action');
        if (localAction) {
            setPageAction(localAction);
        }
    }, [searchParams])

    useEffect(() => {
        if (pageAction === MANAGE_SERVICE_PAGE_ACTION.remove) {
             setShowDeleteConfirmation(true);
        }
    }, [pageAction])

    const initServiceData = async (categories) => {
        // Get the service data if not creating
        if (serviceId && categories) {
            try {
                setLoading(true);
                setLoadingMsg('Loading service data');
                let res = await getServiceDetail({
                    service_id: serviceId,
                    abortSignal: abortSignalRef?.current
                })

                if (res?.data) {
                    const service = res.data;
                    setServiceName(service.name);
                    // if data transfer service, get DTS detail and initialize
                    // the input object accordingly
                    if (isDts && service.transfer_source_id) {
                        setDtsId(service.transfer_source_id);
                        let dtsDetailRes = await getDataTransferSource({
                            id: service.transfer_source_id,
                            abortSignal: abortSignalRef?.current
                        });
                        if (dtsDetailRes.data) {
                            service['dts'] = dtsDetailRes.data;
                        }
                    }

                    // parse the scope and assign appropriate value to scope,
                    // read_access, write_access, stream_access variables
                    let scope = parseScopeStr(service.scope);
                    let serviceCategories = service.category_ids ?
                        categories.filter(c => service.category_ids.includes(c.id)) : [];
                    let input = await mainServiceDetailToRegistrationInput(
                        service, scope, serviceCategories, companyId,
                        companyAbbrev, false);
                    setInput(input);
                }
            } catch(err) {
                if (isCancel(err))
                    return;

                console.error(`failed to get main service detail for service ${serviceId}:`, err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to get main service detail',
                    severity: 'error'
                }));

            } finally {
                setLoading(false);
                setLoadingMsg('');
            }
        }
    }

    const initBreadCrumbs = (serviceType) => {
        if (serviceType) {
            // Breadcrumbs should only be set for Admin
            if (admin) {
                let breadcrumbs = [
                    { name: 'Home', href: URL_HOME },
                    { name: 'Admin', href: URL_ADMIN },
                    { name: `${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Services`, href: `${URL_ADMIN_SERVICES}/${ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[serviceType]}` },
                    { name: 'Service Registration', href: null },
                ];

                dispatch(setBreadcrumbs(breadcrumbs));
            }
        }
    };

    const initPageTitle = (serviceType, serviceName) => {
        let prefix = (admin ? '[Admin] ' : '');
        let title = '';
        switch (pageAction) {
            case MANAGE_SERVICE_PAGE_ACTION.create:
                if (serviceType) {
                    title = `Register ${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Service`;
                }
                else {
                    title = `Register Service`;
                }
                break;
            case MANAGE_SERVICE_PAGE_ACTION.edit:
                title = `Edit: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.view:
                title = `View: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.remove:
                title = `Delete: ${serviceName}`;
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
        }
        dispatch(setPageTitle(prefix + title));
    };

    const handleInputChangeByNameAndValue = (name, value) => {
        setInput({
            ...input,
            [name]: value
        });
    };

    const handleInputChange = (e) => {
        handleInputChangeByNameAndValue(e.target.name, e.target.value);
    };

    /**
     * Updates a field that has a character limit.  The field value will
     * be truncated if it exceeds the limit.
     */
    const handleInputChangeWithLimit = (fieldName, value, limit) => {
        if (value && typeof(value) === 'string') {
            if (value.length > limit) {
                value = value.substring(0, limit);
            }
        }
        handleInputChangeByNameAndValue(fieldName, value);
    };

    const handleInputChangeCompanyRole = (roleId, checked) => {
        const updatedInput = { ...input };
        updatedInput.roles[roleId] = checked;
        setInput(updatedInput);
    }

    // Handle data transfer source input change
    const handleDtsInputChange = (e) => {
        let newInput = { ...input };
        newInput['dts'] = {
            ...input['dts'],
            [e.target.name]: e.target.value
        }
        setInput(newInput);
    };

    const canProceed = () => {
        let valid = formRef.current.reportValidity();
        let serviceCoreErrors = validateServiceCoreData(input);
        let dtsErrors = checkServiceType(serviceType,
            ServiceTypeUtils.TYPES.DATA_TRANSFER) ?
                validateDataTransferSource(input['dts']) : {};
        setFormErrors({ ...formErrors, serviceCoreData: serviceCoreErrors,
            dataTransferSource: dtsErrors })

        valid = valid && isErrorObjectEmpty(serviceCoreErrors) &&
            isErrorObjectEmpty(dtsErrors);

        setError({
            message: (valid ? '' : 'Invalid input fields detected'),
            show: !valid
        })
        return valid;
    };

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    const handleSave = async () => {
        if (canProceed()) {
            let alertErrorMsg = 'Failed to register the service';
            try {
                setLoading(true);
                setLoadingMsg('Registering the service...');

                let providerId = input.provider_id || companyId;
                let newServiceId = null; // used if a new service is created

                let res = await getCompanyDetail({ id: providerId,
                    abortSignal: abortSignalRef?.current });
                let providerName = res?.data?.name;

                if (providerId && providerName) {
                    let dataToSend = await createMainServiceRequestBody(
                        input,
                        providerId,
                        providerName,
                        isDts
                    );

                    let dtsRequest = null;
                    if (isDts) {
                        dtsRequest = dataToSend['data_transfer_source'];
                        delete dataToSend.data_transfer_source
                    }

                    if (isCreating) {
                        if (isDts) {
                            let dtsRes = await createDataTransferSource({ body: dtsRequest, abortSignal: abortSignalRef?.current });
                            dataToSend['transfer_source_id'] = dtsRes.data.id
                        }
                        let res = await createMainService({ body: dataToSend, abortSignal: abortSignalRef?.current });
                        newServiceId = res.data.id
                        dispatch(setAlert({
                            show: true,
                            message: 'Successfully created the service',
                            severity: 'success'
                        }));
                    } else {
                        alertErrorMsg = 'Failed to update the service';
                        if (isDts) {
                            delete dtsRequest.created_by;
                            await updateDataTransferSource({ id: dtsId, body: dtsRequest, abortSignal: abortSignalRef?.current });
                        }
                        await updateMainService({ serviceId, body: dataToSend, abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: 'Successfully updated the service',
                            severity: 'success'
                        }));
                    }

                    // Upload the resource files for the service
                    await Promise.all(
                        uploadResourceFiles(
                            input.service_files,
                            input.additional_files,
                            setLoadingMsg,
                            abortSignalRef
                        )
                    ).catch((error) => {
                        alertErrorMsg = error.message;
                        throw error;
                    });

                    // Navigate to the service version registration page if a new service was created.
                    // Otherwise, navigate to the service list page.
                    if (isCreating) {
                        navigate(
                            getServiceVersionRegisterPage(newServiceId, 
                                input.name, serviceType, 
                                MANAGE_SERVICE_PAGE_ACTION.create, admin),
                            {
                                replace: true
                            }
                        );
                    } else {
                        goToServiceList();
                    }
                } else {
                    console.error(`${alertErrorMsg}. Invalid providerId=${providerId}`)
                    dispatch(setAlert({
                        show: true,
                        message: alertErrorMsg,
                        severity: 'error'
                    }));
                }
            } catch (error) {
                if (isCancel(error))
                    return;

                console.error(alertErrorMsg, error);
                dispatch(setAlert({
                    show: true,
                    message: alertErrorMsg,
                    severity: 'error'
                }));
            } finally {
                setLoading(false);
                setLoadingMsg('');
            }
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmation(false);
        updateSearchParams({ action: MANAGE_SERVICE_PAGE_ACTION.edit });
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const handleDelete = async () => {
        handleCloseDeleteConfirmation();
        setLoading(true);
        setLoadingMsg('Deleting the service');
        try {
            if (isDts) {
                if (input.transfer_source_id) {
                    // Deleting the DTS record also deletes the service record
                    // Therefore, no need to call the deleteService method separately
                    await deleteDataTransferSource({ id: input.transfer_source_id, abortSignal: abortSignalRef?.current });
                } else {
                    throw new Error(`Cannot delete DTS. Invalid transfer source id: ${input?.transfer_source_id}`);
                }
            } else {
                await deleteService({ serviceId, abortSignal: abortSignalRef?.current });
            }

            dispatch(setAlert({
                show: true,
                message: 'Successfully deleted the service',
                severity: 'success'
            }));
            setLoading(false);
            setLoadingMsg('');
            goToServiceList();

        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to delete the service:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to delete the service',
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        };
    };

    const goToServiceList = () => {
            navigate(
                `${admin ? '/admin' : ''}/services/${ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[input.service_type]}`,
                { replace: true }
            );
    };

    // for the backdrop, the 'loading' flag gets cleared before the service 
    // data is fully loaded, so wait until the service data is fully loaded
    const isLoading = (loading || (!isCreating && !input?.name));

    const setServiceCoreDataFormErrors = (update) => {
        setFormErrors((prev) => ({
            ...prev,
            serviceCoreData: { ...(prev?.serviceCoreData || {}), ...update },
        }));
    };

    return (
        <Fragment>
            <ProcessingOverlay open={isLoading} msg={loadingMsg} />

            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <MajorHeading label='Tell us about your service' />

                    <Divider
                        sx={{
                            backgroundColor: 'primary',
                            my: '1rem'
                        }}
                    />

                    <form ref={formRef}>
                        <WizardServiceCore
                            input={input}
                            serviceId={serviceId}
                            categories={categories}
                            companyRoles={companyRoles}
                            handleInputChange={handleInputChange}
                            handleInputChangeByNameAndValue={
                                handleInputChangeByNameAndValue
                            }
                            handleInputChangeWithLimit={
                                handleInputChangeWithLimit
                            }
                            handleInputChangeCompanyRole={
                                handleInputChangeCompanyRole
                            }
                            serviceType={serviceType}
                            formErrors={formErrors.serviceCoreData}
                            setFormErrors={setServiceCoreDataFormErrors}
                            readOnly={isViewing}
                        />

                        {
                            // if admin is creating data transfer service,
                            // display the data transfer source form component
                            admin && checkServiceType(serviceType,
                                 ServiceTypeUtils.TYPES.DATA_TRANSFER) &&
                            (
                                <Fragment>
                                    <Grid item xs={12} sx={{ mt: '2rem', mb: '1rem' }}>
                                        <Divider variant='inset' />
                                    </Grid>
                                    <DataTransferSource
                                        input={input['dts']}
                                        handleInputChange={handleDtsInputChange}
                                        formErrors={formErrors?.dataTransferSource}
                                        readOnly={isViewing}
                                    />
                                </Fragment>
                            )
                        }
                        <BodyText
                            label='* indicates a required field'
                            labelStyle='text-italic'
                        />

                    </form>

                    {/* Service Provider Metrics Information */}
                    <Box sx={{ mt: '2rem'}}>
                        <SectionHeader title="Service Provider Metrics" />
                        Reference the{" "}
                        <Link to={URL_SERVICE_PROVIDER_METRICS} target="_blank">
                            {"Service Provider Metrics"}
                        </Link>
                        {" "} OpenAPI specification to report and manage custom provider metrics.
                    </Box>

                    <Grid container
                        sx={{
                            mt: '2rem'
                        }}
                    >
                        {/* Alert Message */}
                        {
                            error.show &&
                            <Grid item xs={12}>
                                <Alert
                                    severity="error"
                                    variant='filled'
                                    sx={{
                                        mb: '1rem'
                                    }}
                                >
                                    {error.message}
                                </Alert>
                            </Grid>
                        }
                        <Grid container item xs={12} spacing={2}>
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => { updateSearchParams({
                                            action: MANAGE_SERVICE_PAGE_ACTION.remove
                                          })
                                        }}
                                        data-open-modal
                                        aria-controls={deleteDialogRef?.current?.modalId}
                                    />
                                }
                            </Grid>

                            <Grid item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <LinkButton text='Cancel'
                                    href={getServicesPage(admin, serviceType)}
                                    style={{ marginRight: '1rem' }}
                                />
                                {
                                    isCreating &&
                                    <ActionButton text='Create'
                                        onClick={() => handleSave() }
                                    />
                                }
                                {
                                    (isViewing || isDeleting) &&
                                    <ActionButton text='Edit'
                                        onClick={() => updateSearchParams({
                                            action: MANAGE_SERVICE_PAGE_ACTION.edit
                                          })
                                        }
                                    />
                                }
                                {
                                    isEditing &&
                                    <ActionButton text='Save'
                                        onClick={() => handleSave() }
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <OkCancelDialog
                id='confirm-service-deletion-dialog'
                open={!loading && showDeleteConfirmation}
                heading='Confirm Service Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDelete}
                dialogRef={deleteDialogRef}
            >
                {
                    !loading && showDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center margin-top-0' >
                            Are you sure you want to delete the service
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>{serviceName}</strong>?
                        </BodyText>
                        <BodyText labelStyle='text-center' >
                            <i>Deleting this service will irrevocably remove
                            the proxy, the connection, and any subscriptions
                            to the API.</i>
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Fragment >
    );
};

export default MainServiceRegistration;
