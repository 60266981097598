import Grid from '@mui/material/Grid';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMetricServiceProviderMetrics, getServiceProviderMetrics } from "../../apiClient";
import { getMetricServiceAvailability, getMetricServiceDipLatency,
         getMetricServiceIntegrationLatency, getMetricServiceResponseTime
       } from "../../apiClient";
import { setAlert } from '../../app/slices/alert';
import { useAbortController } from '../../hooks';
import LinkButton from '../common/LinkButton';
import MinorHeading from '../common/MinorHeading';
import { createMetricServiceInput, createServiceProviderMetricsInput,
         getTimeRangeFilterParams, getValueAndUnitString, METRIC_GROUP_INTERVAL,
         SERVICE_METRICS_HELP_TABLE_DATA, SERVICE_METRIC_GRAPH_VALUE_TYPE,
         TIME_RANGE_FILTER_OPTIONS,
       } from '../../utils/serviceMetrics';
import MetricsDisplayBlock from './MetricsDisplayBlock';
import MetricHelp from './MetricHelp';
import { ServiceTypeUtils } from '../../utils/utils';


/**
 * This displays the dynamic metrics for a single service version as part of
 * a Service Detail page.
 */
const ServiceVersionDynamicMetrics = ({
    serviceId,
    versionId,
    providerId,
    serviceType,
}) => {
    const {
        abortSignalRef: metricsAbortSignalRef,
        abort: metricsRequestAbort,
        isCancel
    } = useAbortController();

    const dispatch = useDispatch();

    // DIP QoS Metrics
    const [ dipQosMetrics, setDipQosMetrics ] = useState(null);
    const [ loadingDipQosMetrics, setLoadingDipQosMetrics ] = useState(false);

    // Provider dynamic metrics
    const [ serviceProviderDynamicMetrics, setServiceProviderDynamicMetrics ] =
                useState(null);
    const [ loadingProviderDynamicMetrics, setLoadingProviderDynamicMetrics ] =
                useState(false);

    // Display metric help dialog
    const [ showHelpForMetric, setShowHelpForMetric] = useState(null);
    const [ helpDialogTitle, setHelpDialogTitle ] = useState(null);
    const [ isHelpDialogOpen, setHelpDialogOpen ] = useState(false);
    const helpRef = useRef(null);

    const isRestTypeService = useMemo(() => serviceType === ServiceTypeUtils.TYPES.REST, [serviceType]);

    // Whether metrics data is loading or not.
    const loading = useMemo(
        () => loadingDipQosMetrics || loadingProviderDynamicMetrics,
        [loadingDipQosMetrics, loadingProviderDynamicMetrics]
    );

    // Constant parameters for retrieving the dynamic metrics
    const TIME_RANGE_LABEL = '(30-day time range)';
    const TIME_RANGE = TIME_RANGE_FILTER_OPTIONS[2];
    const INTERVAL = METRIC_GROUP_INTERVAL.DAY;

    /**
     * Get the the DIP Dynamic QoS metrics and Service Provider dynamic metrics
     */
    useEffect(() => {
        if (!!serviceId && !!versionId) {
            // Cancel any previous metrics requests
            metricsRequestAbort();
            if (isRestTypeService)
                initDipQosServiceMetrics();
            initProviderDynamicMetrics();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Retrieve the DIP Quality of Service metrics from the back end.
     * This will call a set of methods to retrieve each individual metric.
     * They will be collected into a single table when they are all retrieved.
     */
    const initDipQosServiceMetrics = async () => {
        setLoadingDipQosMetrics(true);
        // Get the metrics for the last 30 days
        let { startTime, endTime } = getTimeRangeFilterParams(TIME_RANGE);

        let metricServiceInput = createMetricServiceInput(serviceId, versionId,
               startTime, endTime, INTERVAL);

        let promises = [];

        promises.push(initializeAvailability(metricServiceInput));
        promises.push(initializeResponseTime(metricServiceInput));
        promises.push(initializeDipLatency(metricServiceInput));
        promises.push(initializeIntegrationLatency(metricServiceInput));

        try {
            let values = await Promise.allSettled(promises);
            setDipQosMetrics(values.map(v => v?.value));
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingDipQosMetrics(false);
        }
    }

    /**
     * Retrieve the DIP QoS Availability metric from the back end.
     */
    const initializeAvailability = async (serviceInput) => {
        let availability = {
            key: `${versionId}-dip-qos-availability`,
            name: SERVICE_METRICS_HELP_TABLE_DATA.availability.label,
            definition: SERVICE_METRICS_HELP_TABLE_DATA.availability.definition,
            methodOfCalculation: SERVICE_METRICS_HELP_TABLE_DATA.availability.methodOfCalculation,
            value: null
        };

        try {
            let res = await getMetricServiceAvailability({
                body: serviceInput,
                abortSignal: metricsAbortSignalRef?.current
            });

            if (!!res.data?.mean) {
                // The value is in decimal form. Therefore, multiply it by
                // 100 to get percentage.
                availability.value = getValueAndUnitString(
                    res.data.mean * 100,
                    res.data.unit,
                    res.data.calculation
                );
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get service availability:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get service availability',
                severity: 'error'
            }));
        } finally {
            return Promise.resolve(availability);
        }
    };

    /**
     * Retrieve the DIP QoS Response Time metric from the back end.
     */
    const initializeResponseTime = async (serviceInput) => {
        let responseTime = {
            key: `${versionId}-dip-qos-response-time`,
            name: SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.label,
            definition: SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.definition,
            methodOfCalculation: SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.methodOfCalculation,
            value: null
        };

        try {
            let res = await getMetricServiceResponseTime({
                body: serviceInput,
                abortSignal: metricsAbortSignalRef?.current
            });

            if (!!res.data?.mean) {
                responseTime.value = getValueAndUnitString(
                    res.data.mean,
                    res.data.unit,
                    res.data.calculation
                );
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get service response time:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get service response time',
                severity: 'error'
            }));
        } finally {
            return Promise.resolve(responseTime);
        }
    };

    /**
     * Retrieve the DIP QoS Platform Latency metric from the back end.
     */
    const initializeDipLatency = async (serviceInput) => {
        let dipLatency = {
            key: `${versionId}-dip-qos-dip-latency`,
            name: SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.label,
            definition: SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.definition,
            methodOfCalculation: SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.methodOfCalculation,
            value: null
        };

        try {
            let res = await getMetricServiceDipLatency({
                body: serviceInput,
                abortSignal: metricsAbortSignalRef?.current
            });
            if (!!res.data?.mean) {
                dipLatency.value = getValueAndUnitString(
                    res.data.mean,
                    res.data.unit,
                    res.data.calculation
                );
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get service Platform Latency:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get service Platform Latency',
                severity: 'error'
            }));
        } finally {
            return Promise.resolve(dipLatency);
        }
    };

    /**
     * Retrieve the DIP QoS Service Processing Time metric from the back end.
     */
    const initializeIntegrationLatency = async (serviceInput) => {
        let serviceLatency = {
            key: `${versionId}-dip-qos-integration-latency`,
            name: SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.label,
            definition: SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.definition,
            methodOfCalculation: SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.methodOfCalculation,
            value: null
        };

        try {
            let res = await getMetricServiceIntegrationLatency({
                body: serviceInput,
                abortSignal: metricsAbortSignalRef?.current
            });

            if (!!res.data?.mean) {
                serviceLatency.value = getValueAndUnitString(
                    res.data.mean,
                    res.data.unit,
                    res.data.calculation
                );
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get Service Processing Time:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get Service Processing Time',
                severity: 'error'
            }));
        } finally {
            return Promise.resolve(serviceLatency);
        }
    };

    /**
     * Retrieve the Service Provider dynamic metrics from the back end.
     */
    const initProviderDynamicMetrics = async () => {
        let spmKeys = await initServiceProviderMetricKeys();
        if (!!spmKeys) {
            setLoadingProviderDynamicMetrics(true);
            await initServiceProviderDynamicMetrics(spmKeys);
            setLoadingProviderDynamicMetrics(false);
        }
    }

    /**
     * Initialize the serviceProviderDynamicMetrics map by setting the
     * keys and values to the metric ID and its initial metadata respectively
     */
    const initServiceProviderMetricKeys = async () => {
        let spmKeys = null;

        try {
            let response = await getServiceProviderMetrics({
                id: serviceId,
                version: versionId,
                abortSignal: metricsAbortSignalRef?.current
            });

            if (response.data?.metrics &&
               (response.data.metrics.length > 0)) {
                setServiceProviderDynamicMetrics([]);
                spmKeys = {};
                response.data.metrics.forEach((spm) => {
                    spmKeys[spm.metric_id] = {
                        key: `${versionId}-${spm.metric_id}`,
                        name: spm.metric_name,
                        calculation: spm.calculation,
                        unit: spm.unit,
                        definition: spm.definition,
                        methodOfCalculation: spm.method_of_calculation
                    }
                });
            }

        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to initialize service provider metric keys', err)
            dispatch(setAlert({
                show: true,
                message: 'Failed to initialize service provider metric keys',
                severity: 'error'
            }));

        } finally {
            return spmKeys;
        }
    }

    /**
     * Initialize the serviceProviderDynamicMetrics map by iterating through all
     * existing keys (metric IDs) in the map and getting and setting the
     * provider metrics for each of the metric IDs.
     */
    const initServiceProviderDynamicMetrics = async (spmKeys) => {
        if (spmKeys && Object.keys(spmKeys).length > 0) {
            let spmData = [];
            let { startTime, endTime } = getTimeRangeFilterParams(TIME_RANGE);

            // Gets and updates the service provider metrics for the given
            // metricId
            const updateSpmData = async (metricId, spm) => {
                try {
                    let metricServiceInput = createServiceProviderMetricsInput(
                        metricId, serviceId, serviceType, startTime, endTime,
                        INTERVAL, spm.unit, spm.calculation
                    );
                    let response = await getMetricServiceProviderMetrics({
                            body: metricServiceInput,
                            abortSignal: metricsAbortSignalRef?.current
                    });

                    if (response.data) {
                        const { results, ...data } = response.data;
                        // Calculate the mean value of the means
                        let mean = results?.reduce((acc, r) => acc + r.mean, 0) / results.length;
                        if (mean) {
                            // If calculation is percentage, multiply the values
                            // by 100
                            if (
                                spm.calculation ===
                                SERVICE_METRIC_GRAPH_VALUE_TYPE.PERCENTAGE
                            ) {
                                mean = mean * 100;
                            }
                            
                            // Limit the number of decimal places to 2
                            mean = mean.toFixed(2);
                        }

                        spmData.push({
                            ...spm,
                            value: getValueAndUnitString(
                                mean,
                                spm.unit,
                                spm.calculation
                            ),
                        });
                        setServiceProviderDynamicMetrics([ ...spmData ]);
                    }
                } catch (err) {
                    if (isCancel(err))
                        return;

                    console.error('failed to get service provider metrics', err)
                    dispatch(setAlert({
                        show: true,
                        message: 'Failed to get service provider metrics',
                        severity: 'error'
                    }));
                }
            }

            let promises = [];
            // asynchronously update the serviceProviderDynamicMetrics state
            // for each metric ID
            for (const [metricId, spm] of Object.entries(spmKeys)) {
                promises.push(updateSpmData(metricId, spm));
            }
            
            try {
                await Promise.all(promises);
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     *  Opens the help dialog for the given metric display object
     */
    const openHelpDialog = (metric, title) => {
        setShowHelpForMetric(metric);
        setHelpDialogTitle(title);
        setHelpDialogOpen(true);
    };

    /**
     *  Closes the help dialog.
     */
    const closeHelpDialog = () => {
        setShowHelpForMetric(null);
        setHelpDialogTitle(null);
        setHelpDialogOpen(false);
    };

    return (
        <>
            <Grid container
                id={`${versionId}-metrics`}
            >
                <Grid item
                    xs={12}
                    sx={{
                        mb: '0.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <MinorHeading
                        label='Dynamic Metrics'
                        labelStyle='margin-y-0'
                        loading={loading}
                    />

                    <LinkButton text='Graphs'
                        href={`/service/qos?serviceId=${serviceId}&providerId=${providerId}&versionId=${versionId}&serviceType=${serviceType}`}
                        disabled={!serviceId || !providerId}
                    />
                </Grid>

                { /* DIP-computed QOS metrics */ }
                {
                    isRestTypeService && !loadingDipQosMetrics && !!dipQosMetrics &&
                    <MetricsDisplayBlock
                        title='Quality of Service'
                        lineTwo={TIME_RANGE_LABEL}
                        metrics={dipQosMetrics}
                        openHelpDialog={openHelpDialog}
                        helpRef={helpRef}
                    />
                }

                { /* Provider dynamic metrics */ }
                {
                    (!!serviceProviderDynamicMetrics &&
                        serviceProviderDynamicMetrics.length > 0) &&
                    <MetricsDisplayBlock
                        title='Provider Metrics'
                        lineTwo={TIME_RANGE_LABEL}
                        metrics={serviceProviderDynamicMetrics}
                        openHelpDialog={openHelpDialog}
                        helpRef={helpRef}
                        sort={true}
                        sortBy='name'
                    />
                }
            </Grid>

            <MetricHelp
                id={`${versionId}-metrics-help`}
                isDialogOpen={isHelpDialogOpen}
                closeDialog={closeHelpDialog}
                rows={showHelpForMetric}
                heading={helpDialogTitle}
                helpRef={helpRef}
            />
        </>
    );
};

export default ServiceVersionDynamicMetrics;
