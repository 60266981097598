import Skeleton from '@mui/material/Skeleton';

import { Fragment, memo, useEffect, useState } from 'react';

import SelectObject from './SelectObject';

/**
 * This provides a drop-down selector to select a service type.
 *
 * @param {array(object)} options               list of service type options
 * @param {function}      handleSelect          selection handler; note this
 *                                              should handle just the
 *                                              selected service, NOT the
 *                                              selection event
 * @param {object}        selected              service to select, if any
 * @param {string}        typeLabel             label for the select control
 * @param {string}        typeIdAttribute       name of the attribute in the
 *                                              options that contains the
 *                                              unique type identifier
 * @param {string}        typeValueAttribute    name of the attribute in the
 *                                              options that contains the
 *                                              type name
 * @param {boolean}       disabled              true to disable the selector
 */
const ServiceTypeSelect = ({
    options,
    handleSelect,
    selected,
    typeLabel='Type',
    typeIdAttribute='id',
    typeValueAttribute='name',
    disabled,
}) => {

    const [types, setTypes] = useState(null);

    useEffect(() => {
        if (options && options.length) {
            setTypes([...options]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return (
        <Fragment>
            {
                (!types || !types.length) ? (
                    <Skeleton variant='rounded' height='3rem' />
                ) : (
                    <SelectObject
                        id='common-service-select'
                        name='service-select'
                        label={typeLabel}
                        labelStyle='margin-top-0'
                        value={selected}
                        itemList={options}
                        idAttribute={typeIdAttribute}
                        valueAttribute={typeValueAttribute}
                        handleSelect={handleSelect}
                        disabled={disabled}
                    />
                )
            }
        </Fragment>
    );
};

export default memo(ServiceTypeSelect);
