import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPlatformReleases, selectFetchingPlatformReleases, selectPlatformReleases } from '../app/slices/news';
import { setPageTitle } from '../app/slices/page';
import acceleratingImg from '../assets/about/accelerating_header.png';
import compassImg from '../assets/about/compass.png';
import infographicImg from '../assets/about/infographic.png';
import platformImg from '../assets/about/platform_features.png';
import skyscrapersImg from '../assets/about/skyscrapers.png';
import BodyText from '../components/common/BodyText';
import MajorHeading from '../components/common/MajorHeading';
import SideNavBar from '../components/common/SideNavBar';
import StyledLink from '../components/common/StyledLink';
import { useAbortController } from '../hooks';
import { setAlert } from '../app/slices/alert';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';


/**
 * This provides the "About" page, describing the DIP Catalog.  It should be
 * available to anyone, whether logged in or not.
 */
function About() {

  const dispatch = useDispatch();

  const { abortSignalRef } = useAbortController();

  const fetchingReleases = useSelector(selectFetchingPlatformReleases);
  const platformReleases = useSelector(selectPlatformReleases);

  useEffect(() => {
    dispatch(setPageTitle('About'));

    dispatch(fetchPlatformReleases({
        abortSignalRef: abortSignalRef
    }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get the platform releases', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get the platform releases',
            severity: 'error'
        }));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const latestReleaseVersion = platformReleases?.[0]?.release ?? '';
  return (
    <Box id='about'
      sx={{
        position: 'relative',
      }}
    >
        {/* Page banner */}
        <Box
            component="img"
            alt="accelerating NAS transformation for advanced, data-drive, digital services to promote efficient aviation operations"
            src={acceleratingImg}
            sx={bannerStyle}
        />

        <BodyText
            id="version"
            labelStyle="display-flex flex-justify-end margin-top-2 margin-bottom-2 text-gray"
            label={<>
              Platform Version: {latestReleaseVersion}
              {
                fetchingReleases && (
                  <CircularProgressIndicator
                      altText="Fetching the latest release version..."
                      sx={{
                          ml: "1rem",
                      }}
                  />
                )
              }
            </>
            }
        />

        { /* Page content */ }
        <Grid container>

            { /* Left side: Navigation to each About section */ }
            <Grid item
                xs={0}
                sm={2}
                sx={{
                    position: 'relative',
                    display: {
                        xs: 'none',
                        sm: 'block'
                    }
                }}
            >
                <SideNavBar items={sideBarNavItems} />
            </Grid>

            { /* Right side: content */ }
            <Grid item container xs={12} sm={10} sx={{ pr: '0rem' }}>

                { /*
                   * First card: DIP Project and Challenges DIP Addresses
                   */ }
                <Card id='dip-project-card'
                    sx={{
                        padding: '1rem',
                    }}
                >
                    <Grid container spacing={2} >
                        { /* First card + left column : text and graphic */}
                        <Grid item xs={12} md={5} >
                            <Header id='dip-project-header'
                                title='DIP Project'
                            />
                            <Description id='dip-project-description' >
                                {dip_project_description}
                            </Description>
                            <Box
                                component='img'
                                alt=''
                                src={compassImg}
                                sx={imgStyle}
                            />
                        </Grid>
                        { /* First card + right column : graphic and text */ }
                        <Grid item xs={12} md={7} >
                            <Box
                                component='img'
                                alt='DIP is a common, simplified interface to integrated, processed information'
                                src={infographicImg}
                                sx={imgStyle}
                            />
                            <Header id='challenges-header'
                                title='Challenges DIP is Addressing'
                            />
                            <Box
                                boxSizing='border-box'
                                sx={{
                                    marginLeft: '1rem',
                                    paddingLeft: '1rem'
                                }}
                            >
                                { /* The text is a list of bulleted items */ }
                                <List
                                    sx={{
                                        listStyleType: 'disc',
                                        paddingTop: 0,
                                        '& .MuiListItem-root': {
                                            display: 'list-item',
                                        }
                                    }}
                                >
                                  {
                                    challenge_list.map((item, idx) => (
                                        <ListItem key={idx}
                                            sx={{
                                                paddingTop: 0,
                                                paddingLeft: '.1rem',
                                                display: 'inline-block'
                                            }}
                                        >
                                            <label className='usa-label margin-top-05'>
                                                <strong>
                                                    {item.title}&nbsp;
                                                </strong>
                                                {item.text}
                                            </label>
                                        </ListItem>
                                    ))
                                  }
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>

                { /*
                   * Second card: Platform
                   */ }
                <Card id='platform-card'
                    sx={{
                        padding: '1rem',
                        marginTop: '2rem'
                    }}
                >
                    <Grid container >
                        { /* Second card + first row : text */}
                        <Grid item xs={12} >
                            <Header id='platform-header'
                                title='Platform'
                            />
                            <Description id='platform-description' >
                                {platform_description}
                            </Description>
                        </Grid>
                        { /* Second card + second row: graphic */}
                        <Grid item xs={12}
                            sx={{
                                display: 'flex',
                                justifyContents: 'center',
                                width: '100%',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                                marginLeft: '.5rem',
                            }}
                         >
                            <Box
                                component='img'
                                alt='Platform features are digital services, data integration, common platform, advanced technologies, performance monitoring, and sustainability. Digital services provide building blocks for reuse.  Data integration allows combination and simplification of data for holistic information.  A common platform provides a cloud-based (smaller) footprint and standard APIS for simpler integration.  Advanced technologies apply AI/ML to improve scalability and extensibility of services.  Performance monitoring promotes quality and builds trust in information.  Sustainability informs decision making to optimize efficiency.'
                                src={platformImg}
                                sx={imgStyle}
                            />
                        </Grid>
                        { /* Second card + third row: left and right text */}
                        <Grid item container >
                            { /* Left side text */ }
                            <Grid item xs={12} md={6} >
                                <Header id='features-header'
                                    title='Platform Features'
                                />
                                <Box
                                    boxSizing='border-box'
                                    sx={{
                                        marginLeft: '1rem',
                                        paddingLeft: '1rem'
                                    }}
                                >
                                    { /* Text is a list of bulleted items */ }
                                    <List
                                        sx={{
                                            listStyleType: 'disclosure-closed',
                                            paddingTop: 0,
                                            '& .MuiListItem-root': {
                                                display: 'list-item',
                                            }
                                        }}
                                    >
                                      {
                                        platform_features.map((item, idx) => (
                                          <ListItem key={idx}
                                              sx={{
                                                  paddingTop: 0,
                                                  paddingLeft: '.1rem',
                                              }}
                                          >
                                              <BodyText
                                                  label={item}
                                                  labelStyle='margin-top-05'
                                              />
                                          </ListItem>
                                        ))
                                      }
                                    </List>
                                </Box>
                            </Grid>

                            { /* Right side text */ }
                            <Grid item container xs={12} md={6} >
                                <div>
                                    <Header id='cloud-header'
                                        title='Cloud-Based Architecture'
                                    />
                                    <Description id='cloud-description' >
                                        {cloud_description}
                                    </Description>
                                </div>
                                <div>
                                    <Header id='security-header'
                                        title='Platform Security'
                                    />
                                    <Description id='security-description' >
                                        {security_description}
                                    </Description>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                { /*
                   * Third card: Partnership and Contact Us
                   */ }
                <Card id='partnership-card'
                    sx={{
                        padding: '1rem',
                        marginTop: '2rem'
                    }}
                >
                    <Grid container >
                        { /* Third card + first row : text */}
                        <Grid item xs={12} >
                            <Header id='partnership-header'
                                title='Partnership'
                            />
                            <Description id='partnership-description' >
                                {partnership_description}
                            </Description>
                        </Grid>
                        { /* Third card + second row : text
                           * This is more complicated because of embedded links
                           */}
                        <Grid item xs={12} >
                            <Header id='contact-header'
                                title='Contact Us'
                            />
                            <Box
                                sx={{
                                    paddingLeft: '.5rem',
                                    paddingRight: '.5rem',
                                    paddingBottom: '.5rem',
                                }}
                            >
                                <BodyText
                                    label='To learn more about DIP, events and partnerships, please visit'
                                    labelStyle='margin-top-1'
                                >
                                    <span>
                                        &nbsp;
                                    </span>
                                    <StyledLink href={atmxWebsite} >
                                        {atmxWebsite}
                                    </StyledLink>
                                    <span>
                                        &nbsp;or contact us at&nbsp;
                                    </span>
                                    <StyledLink href={`mailto:${contactEmail}`}>
                                        {contactEmail}
                                    </StyledLink>
                                    <span>
                                        .
                                    </span>
                                </BodyText>
                            </Box>
                        </Grid>
                        { /* Thid card + third row : graphic */}
                        <Grid item xs={12}
                            sx={{
                                display: 'flex',
                                justifyContents: 'center',
                                width: '100%',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                                marginLeft: '.5rem',
                            }}
                         >
                            <Box
                                component='img'
                                alt='a city scape with a sky containing an airliner, small aircraft, helicopter, and drones'
                                src={skyscrapersImg}
                                sx={imgStyle}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    </Box >
  )
}


//
// Common styles
//
const bannerStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '7px'
};

const imgStyle = {
  width: '100%',
  objectFit: 'contains',
  borderRadius: '7px'
};

const Header = ({ title, id, ...props }) => (
    <MajorHeading
        label={title}
        labelStyle='margin-left-1 margin-top-1 margin-bottom-1'
        id={id}
    />
);

const Description = (props) => (
    <BodyText labelStyle='display-flex text-pre-line margin-1' {...props}
    />
);

// Side bar navigation:
// - title  section title for the side navigation bar
// - href   #anchor name for the side navigation bar
const sideBarNavItems = [
  {
    title: 'DIP Project',
    href: '#dip-project-header',
  },
  {
    title: 'Platform',
    href: '#platform-header',
  },
  {
    title: 'Partnership',
    href: '#partnership-header',
  },
  {
    title: 'Contact Us',
    href: '#contact-header',
  }
];

//
// Content text
//

const contactEmail = process.env.REACT_APP_INQUIRY_EMAIL;
const atmxWebsite = 'https://nari.arc.nasa.gov/atmx-dip';

const dip_project_description = 'The Air Traffic Management – eXploration (ATM-X) Digital Information Platform (DIP) project is designed to accelerate the transformation of the National Airspace System (NAS) and airspace operations by developing a foundation for advanced, data-driven, digital services for both traditional and emergent operations towards cohesive decision making.\n\nDIP aims to design, develop and demonstrate a digital information ecosystem to improve airspace operations decision making. The primary focus of DIP is to establish an easily accessible cloud-based Digital Information Platform utilized by data and service providers and operators to gather and access both real-time and historical data upon which new data-driven services are developed.';

const challenge_list = [
  {
    title: 'Increased Access to NAS Information:',
    text:  'Build an easy access to organized airspace data and information via common and simplified API'
  },
  {
    title: 'Improved Data Quality:',
    text:  'Provide access to trusted, reliable and validated data sources with safeguards'
  },
  {
    title: 'High Reuse Solutions:',
    text:  'Support data-driven predictive models and services that are scalable and adaptable'
  },
  {
    title: 'Commercialization Methodology:',
    text:  'Develop a cloud-based architecture that enables an ecosystem of high reuse solutions across organizations'
  },
  {
    title: 'Services for Efficiency and Sustainability:',
    text:  'Provide access to advanced services to increase efficiency and predictability, as well as to manage digital re-route, irregular operations, etc.'
  }
];

const platform_description = 'The platform features a catalog of services provided by and available to the aviation community. The catalog allows users to browse, search, try, connect, subscribe, and register services. Catalog users can browse and search services in the catalog, as well as inspect service APIs and try out requesting responses from the services. Users can obtain connection credentials to access services and subscribe to services.  Service providers can register their services, providing information and metadata to facilitate discovery and access to their services.  While all users may access services developed by NASA and any non-NASA services that have been approved for public release, official DIP partners have access to an expanded catalog of services and may register new services which can optionally be made available only to other official DIP partners.\n\nThe platform provides performance metrics pertaining to the qualities of services. Service Providers can supply additional metrics pertaining to the performance of the service.';

const platform_features = [
  'REST, streaming, and file transfer services',
  'Service registrations and subscriptions',
  'Service management: access, visibility, versioning and lifecycle',
  'Dashboard and performance metrics about services and data',
  'Planned distributed architecture supporting Peer-to-Peer transactions'
];

const cloud_description = 'DIP Catalog provides access to real-time and historical services’ digital information while facilitating data integration and service layering.';

const security_description = 'DIP Catalog and the back-end API security utilize an OAuth2 identity provider that is integrated with NASA Guest Login using SAML. JSON Web Tokens (JWT) are generated using RSA key pairs and are validated for all API calls.';

const partnership_description = 'NASA is partnering with flight operators, new entrants, service providers, data integrators, platform providers, and other stakeholders who are committed to building a community-supported cloud-based ecosystem of data services to enhance future aviation operations.';


export default About;
