import Grid from "@mui/material/Grid";
import { lazy, memo, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getMetricServiceAvailability, getMetricServiceDipLatency,
  getMetricServiceIntegrationLatency, getMetricServiceResponseTime
} from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { useAbortController } from "../../hooks";
import {
  createMetricServiceInput, getShadedTraceAxes, getTimeRangeFilterParams,
  manipulateXaxisPerGroupInterval, SERVICE_METRICS_HELP_TABLE_DATA,
  TIME_RANGE_FILTER_OPTIONS, UNIT_FILTER_OPTIONS
} from "../../utils/serviceMetrics";
import CircularProgressIndicator from "../common/CircularProgressIndicator";

const ServiceGraph = lazy(() => import("./ServiceGraph"));

const ServiceMetrics = ({
  serviceId,
  versionId,
  metricTimeRange = TIME_RANGE_FILTER_OPTIONS[3],
  metricUnit = UNIT_FILTER_OPTIONS[1],
  dynamicUpdate = true, // whether or not to dynamically update the graphs if metricTimeRange/metricUnit changes
  loading = false
}) => {

  let {
    abortSignalRef: metricsAbortSignalRef,
    abort: metricsRequestAbort,
    isCancel
  } = useAbortController();

  const dispatch = useDispatch();

  /**
   * response time: the total amount of time to handle a request/response data
   * flow.
   * Total response time =
   *                integration latency + DIP latency (API Gateway Latency)
   * integration latency: The time between when API Gateway relays a request
   *           to the backend and when it receives a response from the backend.
   * DIP latency: the transit time of request and response. API Gateay latency.
   */
  const [serviceResponseTimeData, setServiceResponseTimeData] = useState(null);
  const [serviceIntegrationLatencyData, setServiceIntegrationLatencyData] = useState(null);
  const [serviceDipLatencyData, setServiceDipLatencyData] = useState(null);
  const [serviceAvailabilityData, setServiceAvailabilityData] = useState(null);

  const [loadingServiceAvailability, setLoadingServiceAvailability] = useState(false);
  const [loadingServiceResponseTime, setLoadingServiceResponseTime] = useState(false);
  const [loadingServiceIntegrationLatencyData, setLoadingServiceIntegrationLatencyData] = useState(false);
  const [loadingServiceDipLatencyData, setLoadingServiceDipLatencyData] = useState(false);

  const [xAxisTitle, setXaxisTitle] = useState(null);
  const [xAxisTickFormat, setXaxisTickFormat] = useState(null);
  const [xAxisHoverFormat, setXaxisHoverFormat] = useState(null);
  const [xAxisA11yDateFormat, setXaxisA11yDateFormat] = useState(null);

  const [metricGraphStartTime, setMetricGraphStartTime] = useState(null);
  const [metricGraphEndTime, setMetricGraphEndTime] = useState(null);

  useEffect(() => {
    if (!dynamicUpdate) {
      initServiceMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    setXaxisTitle(metricUnit.xAxisTitle);
    setXaxisTickFormat(metricUnit.xAxisTickFormat);
    setXaxisHoverFormat(metricUnit.xAxisHoverFormat);
    setXaxisA11yDateFormat(metricUnit.xAxisA11yDateFormat);

    if (dynamicUpdate) {
      initServiceMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricTimeRange, metricUnit]);

  const initServiceMetrics = async () => {
    // cancel any previous metrics requests
    metricsRequestAbort();

    let { startTime, endTime } = getTimeRangeFilterParams(metricTimeRange);
    setMetricGraphStartTime(startTime);
    setMetricGraphEndTime(endTime);

    let metricServiceInput = createMetricServiceInput(serviceId, versionId, startTime, endTime, metricUnit.groupInterval);
    initializeAvailabilityData(metricServiceInput);
    initializeResponseTimeData(metricServiceInput);
    initializeIntegrationLatencyData(metricServiceInput);
    initializeDipLatencyData(metricServiceInput);
  }

  const initializeAvailabilityData = async (serviceInput) => {
    try {
      setLoadingServiceAvailability(true);
      let res = await getMetricServiceAvailability({ body: serviceInput, abortSignal: metricsAbortSignalRef?.current })

      if (res.data) {
        const data = res.data;

        // the value for y_axis is in decimal form. Therefore, multiply it by
        // 100 to get percentage.
        if (!!data?.series?.x_axis && !!data?.series?.y_axis) {
          data.series.y_axis = data.series.y_axis.map(y => y * 100);
          data.max = data.max * 100;
          data.min = data.min * 100;
          data.std = data.std * 100;
          data.mean = data.mean * 100;

          data.series.x_axis = manipulateXaxisPerGroupInterval(
            data.series.x_axis, metricUnit.groupInterval);
        }
        setServiceAvailabilityData({ ...res.data });
      }

      setLoadingServiceAvailability(false);
    } catch (err) {
      if (isCancel(err))
        return;

      console.error('failed to get service availability:', err.message);
      dispatch(setAlert({
        show: true,
        message: 'Failed to get service availability',
        severity: 'error'
      }));

      setLoadingServiceAvailability(false);
    };
  };

  const initializeResponseTimeData = async (serviceInput) => {
    try {
      setLoadingServiceResponseTime(true);
      let res = await getMetricServiceResponseTime({ body: serviceInput, abortSignal: metricsAbortSignalRef?.current })
      if (res.data) {
        const data = res.data;
        if (!!data?.series?.ymin_axis && !!data?.series?.ymax_axis && !!data?.series?.x_axis) {
          data.series.x_axis = manipulateXaxisPerGroupInterval(data.series.x_axis, metricUnit.groupInterval);
          data.series.shadedTraceAxes = getShadedTraceAxes(data.series.x_axis, data.series.ymin_axis, data.series.ymax_axis);
        }
        setServiceResponseTimeData({ ...res.data });
      }

      setLoadingServiceResponseTime(false);
    } catch (err) {
      if (isCancel(err))
        return;

      console.error('failed to get service response time:', err.message);
      dispatch(setAlert({
        show: true,
        message: 'Failed to get service response time',
        severity: 'error'
      }));

      setLoadingServiceResponseTime(false);
    };
  };

  const initializeIntegrationLatencyData = async (serviceInput) => {
    try {
      setLoadingServiceIntegrationLatencyData(true);
      let res = await getMetricServiceIntegrationLatency({ body: serviceInput, abortSignal: metricsAbortSignalRef?.current })
      if (res.data) {
        const data = res.data;
        if (!!data?.series?.ymin_axis && !!data?.series?.ymax_axis && !!data?.series?.x_axis) {
          data.series.x_axis = manipulateXaxisPerGroupInterval(data.series.x_axis, metricUnit.groupInterval);
          data.series.shadedTraceAxes = getShadedTraceAxes(data.series.x_axis, data.series.ymin_axis, data.series.ymax_axis);
        }
        setServiceIntegrationLatencyData({ ...res.data });
      }

      setLoadingServiceIntegrationLatencyData(false);
    } catch (err) {
      if (isCancel(err))
        return;

      console.error('failed to get service integration latency:', err.message);
      dispatch(setAlert({
        show: true,
        message: 'Failed to get service integration latency',
        severity: 'error'
      }));

      setLoadingServiceIntegrationLatencyData(false);
    }
  };

  const initializeDipLatencyData = async (serviceInput) => {
    try {
      setLoadingServiceDipLatencyData(true);
      let res = await getMetricServiceDipLatency({ body: serviceInput, abortSignal: metricsAbortSignalRef?.current })
      if (res.data) {
        const data = res.data;
        if (!!data?.series?.ymin_axis && !!data?.series?.ymax_axis &&
          !!data?.series?.x_axis) {
          data.series.x_axis = manipulateXaxisPerGroupInterval(
            data.series.x_axis, metricUnit.groupInterval);
          data.series.shadedTraceAxes = getShadedTraceAxes(
            data.series.x_axis, data.series.ymin_axis,
            data.series.ymax_axis);
        }
        setServiceDipLatencyData({ ...res.data });
      }

      setLoadingServiceDipLatencyData(false);
    } catch (err) {
      if (isCancel(err))
        return;

      console.error('failed to get service DIP latency:', err.message);
      dispatch(setAlert({
        show: true,
        message: 'Failed to get service DIP latency',
        severity: 'error'
      }));

      setLoadingServiceDipLatencyData(false);
    }
  };

  return (
    <Grid container item rowSpacing={2}>
      <Suspense fallback={
        <CircularProgressIndicator
          altText='Loading service metrics...'
        />}>
        <ServiceGraph
          id='graph-availability'
          title={SERVICE_METRICS_HELP_TABLE_DATA.availability.label}
          data={serviceAvailabilityData}
          loading={loading || loadingServiceAvailability}
          methodOfCalculation={SERVICE_METRICS_HELP_TABLE_DATA.availability.methodOfCalculation}
          definition={SERVICE_METRICS_HELP_TABLE_DATA.availability.definition}
          timeRange={metricTimeRange.name}
          xAxisTitle={xAxisTitle}
          xAxisTickFormat={xAxisTickFormat}
          xAxisHoverFormat={xAxisHoverFormat}
          xAxisA11yDateFormat={xAxisA11yDateFormat}
          graphStartTime={metricGraphStartTime}
          graphEndTime={metricGraphEndTime}
        />

        <ServiceGraph
          id='graph-total-response-time'
          title={SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.label}
          data={serviceResponseTimeData}
          loading={loading || loadingServiceResponseTime}
          methodOfCalculation={SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.methodOfCalculation}
          definition={SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.definition}
          timeRange={metricTimeRange.name}
          xAxisTitle={xAxisTitle}
          xAxisTickFormat={xAxisTickFormat}
          xAxisHoverFormat={xAxisHoverFormat}
          xAxisA11yDateFormat={xAxisA11yDateFormat}
          graphStartTime={metricGraphStartTime}
          graphEndTime={metricGraphEndTime}
        />

        <ServiceGraph
          id='graph-platform-latency'
          title={SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.label}
          data={serviceDipLatencyData}
          loading={loading || loadingServiceDipLatencyData}
          methodOfCalculation={SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.methodOfCalculation}
          definition={SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.definition}
          timeRange={metricTimeRange.name}
          xAxisTitle={xAxisTitle}
          xAxisTickFormat={xAxisTickFormat}
          xAxisHoverFormat={xAxisHoverFormat}
          xAxisA11yDateFormat={xAxisA11yDateFormat}
          graphStartTime={metricGraphStartTime}
          graphEndTime={metricGraphEndTime}
        />

        <ServiceGraph
          id='graph-processing-time'
          title={SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.label}
          data={serviceIntegrationLatencyData}
          loading={loading || loadingServiceIntegrationLatencyData}
          methodOfCalculation={SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.methodOfCalculation}
          definition={SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.definition}
          timeRange={metricTimeRange.name}
          xAxisTitle={xAxisTitle}
          xAxisTickFormat={xAxisTickFormat}
          xAxisHoverFormat={xAxisHoverFormat}
          xAxisA11yDateFormat={xAxisA11yDateFormat}
          graphStartTime={metricGraphStartTime}
          graphEndTime={metricGraphEndTime}
        />
      </Suspense>
    </Grid>
  )
};

export default memo(ServiceMetrics);
