import Box from "@mui/material/Box";

import AsyncApiComponent from "@asyncapi/react-component/browser"; // Using the Universal Module Definition module as other modules do not render the spec.
import "@asyncapi/react-component/styles/default.min.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getObjectFromS3 } from "../apiClient";
import { setAlert } from "../app/slices/alert";
import { setPageTitle } from "../app/slices/page";
import CircularProgressIndicator from "../components/common/CircularProgressIndicator";
import { useAbortController } from "../hooks";
import "./AsyncApiSpec.css";

/**
 * This provides the display of the API of a service.    This page is displayed
 * when viewing the service detail page for a non-REST service then clicking
 * the API button.
 */

function AsyncApiSpec() {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const [spec, setSpec] = useState(null);

    useEffect(() => {
        let asyncApiLocation = searchParams.get("asyncApiLocation");
        let serviceName = searchParams.get("name");

        dispatch(setPageTitle("AsyncAPI: " + serviceName));

        getObjectFromS3({
            key: asyncApiLocation,
            abortSignal: abortSignalRef?.current,
        })
            .then((res) => {
                if (res && res["data"]) {
                    setSpec(res.data);
                } else {
                    dispatch(
                        setAlert({
                            show: true,
                            message: "Invalid AsyncAPI Specification",
                            severity: "error",
                        })
                    );
                }
            })
            .catch((err) => {
                if (isCancel(err)) return;

                console.error("Cannot get the AsyncAPI spec:", err.message);
                dispatch(
                    setAlert({
                        show: true,
                        message: "Failed to get the AsyncAPI spec file",
                        severity: "error",
                    })
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {
                spec !== null ? (
                    <AsyncApiComponent schema={spec} />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CircularProgressIndicator 
                            type='large'
                            altText='Generating API...'
                        />
                    </Box>
                )
            }
        </Box>
    );
}

export default AsyncApiSpec;
