import Grid from "@mui/material/Grid";
import { Fragment, lazy, memo, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMetricServiceProviderMetrics, getServiceProviderMetrics } from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { useAbortController } from "../../hooks";
import {
  createServiceProviderMetricsInput, getShadedTraceAxes,
  getTimeRangeFilterParams, manipulateXaxisPerGroupInterval,
  SERVICE_METRIC_GRAPH_VALUE_TYPE, TIME_RANGE_FILTER_OPTIONS, UNIT_FILTER_OPTIONS
} from "../../utils/serviceMetrics";
import CircularProgressIndicator from "../common/CircularProgressIndicator";

const ServiceGraph = lazy(() => import("./ServiceGraph"));

// This component first fetches the metric IDs and metadata and then gets the 
// provider metrics for each of the metric IDs.
const ServiceProviderMetrics = ({
  serviceId,
  versionId,
  serviceType,
  consumerNameById,
  metricTimeRange = TIME_RANGE_FILTER_OPTIONS[3],
  metricUnit = UNIT_FILTER_OPTIONS[1],
  dynamicUpdate = true, // wheter or not to dynamically update the graphs if 
  // metricTimeRange/metricUnit changes
  loading = false,
}) => {
  const dispatch = useDispatch();

  const { abortSignalRef, abort, isCancel } = useAbortController();

  const [serviceProviderMetricData, setServiceProviderMetricData] = useState(null);

  const [xAxisTitle, setXaxisTitle] = useState(null);
  const [xAxisTickFormat, setXaxisTickFormat] = useState(null);
  const [xAxisHoverFormat, setXaxisHoverFormat] = useState(null);
  const [xAxisA11yDateFormat, setXaxisA11yDateFormat] = useState(null);

  const [metricGraphStartTime, setMetricGraphStartTime] = useState(null);
  const [metricGraphEndTime, setMetricGraphEndTime] = useState(null);

  useEffect(() => {
    if (!dynamicUpdate) {
      updateProviderMetricState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    setXaxisTitle(metricUnit.xAxisTitle);
    setXaxisTickFormat(metricUnit.xAxisTickFormat);
    setXaxisHoverFormat(metricUnit.xAxisHoverFormat);
    setXaxisA11yDateFormat(metricUnit.xAxisA11yDateFormat);

    if (dynamicUpdate) {
      updateProviderMetricState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricTimeRange, metricUnit]);

  // initialize the serviceProviderMetricData state
  const updateProviderMetricState = async () => {
    abort();
    let initialSpmData = await initServiceProviderMetricKeys();
    await initServiceProviderMetricData(initialSpmData);
  }

  // initialize the serviceProviderMetricData map by setting the 
  // keys and values to the metric ID and its initial metadata respectively
  const initServiceProviderMetricKeys = async () => {
    let spmData = {};

    try {
      let response = await getServiceProviderMetrics({ id: serviceId, version: versionId, abortSignal: abortSignalRef?.current })

      response.data?.metrics?.forEach((spm) => {
        spmData[spm.metric_id] = {
          name: spm.metric_name,
          calculation: spm.calculation,
          unit: spm.unit,
          results: [],
          loading: true,
          definition: spm.definition,
          methodOfCalculation: spm.method_of_calculation
        }
      })

    } catch (err) {
      if (isCancel(err))
        return;

      console.error('failed to initialize service provider metric keys', err)
      dispatch(setAlert({
        show: true,
        message: 'Failed to initialize service provider metric keys',
        severity: 'error'
      }));

    } finally {
      setServiceProviderMetricData({ ...spmData });
      return spmData;
    }
  }

  // initialize the serviceProviderMetricData map by iterating through all 
  // existing keys (metric IDs) in the map and getting and setting the 
  // provider metrics for each of the metric IDs.
  const initServiceProviderMetricData = async (spmData) => {
    if (spmData && Object.keys(spmData).length > 0) {
      let { startTime, endTime } = getTimeRangeFilterParams(metricTimeRange);

      setMetricGraphStartTime(startTime);
      setMetricGraphEndTime(endTime);

      const updateSpmData = async (metricId, spm) => {
        try {
          let metricServiceInput = createServiceProviderMetricsInput(
            metricId, serviceId, serviceType, startTime, endTime,
            metricUnit.groupInterval, spm.unit, spm.calculation
          );
          let response = await getMetricServiceProviderMetrics(
            { body: metricServiceInput, abortSignal: abortSignalRef?.current });

          if (response.data) {
            let { results, ...data } = response.data;

            if (results.length) {
              results = results
                .filter(result => !!result?.series?.ymin_axis && !!result?.series?.ymax_axis &&
                  !!result?.series?.x_axis)
                .map((result) => {
                  const { series } = result;
                  series.x_axis = manipulateXaxisPerGroupInterval(
                    series.x_axis, metricUnit.groupInterval);
                  series.shadedTraceAxes = getShadedTraceAxes(
                    series.x_axis, series.ymin_axis,
                    series.ymax_axis);

                  // if calculation is percentage, multiply the values by 100
                  if (spm.calculation === SERVICE_METRIC_GRAPH_VALUE_TYPE.PERCENTAGE) {
                    series.y_axis = series.y_axis.map(y => y * 100);
                    result.max = result.max * 100;
                    result.min = result.min * 100;
                    result.std = result.std * 100;
                    result.mean = result.mean * 100;
                  }

                  // Limit the number of decimal places to 2 for metrics
                  result.max = result.max?.toFixed(2);
                  result.min = result.min?.toFixed(2);
                  result.std = result.std?.toFixed(2);
                  result.mean = result.mean?.toFixed(2);
                  
                  // Add unit and calculation to the result
                  result.calculation = data.calculation;
                  result.unit = data.unit;
                  return result;
                })
            }

            spmData[metricId] = {
              ...spm,
              ...data,
              results,
              loading: false,
            }

            setServiceProviderMetricData({ ...spmData });
          }
        } catch (err) {
          if (isCancel(err))
            return;

          console.error('failed to get service provider metrics', err)
          dispatch(setAlert({
            show: true,
            message: 'Failed to get service provider metrics',
            severity: 'error'
          }));
        }
      }

      for (const [metricId, spm] of Object.entries(spmData)) {
        // asynchronously update the serviceProviderMetricData state for each 
        // metric ID 
        updateSpmData(metricId, spm);
      }
    }
  }

  return (
    <Grid container item rowSpacing={2}>
      {
        serviceProviderMetricData && (
          <Suspense
            fallback={
              <CircularProgressIndicator
                altText='Loading service provider metrics...'
              />}
          >
            {
              Object.keys(serviceProviderMetricData).map(key => (
                serviceProviderMetricData[key].results.map((result, index) => (
                  <Fragment key={`${key}-${index}`}>
                    <ServiceGraph
                      title={serviceProviderMetricData[key].name}
                      data={result}
                      loading={loading || serviceProviderMetricData[key].loading}
                      methodOfCalculation={serviceProviderMetricData[key].methodOfCalculation}
                      definition={serviceProviderMetricData[key].definition}
                      timeRange={metricTimeRange.name}
                      xAxisTitle={xAxisTitle}
                      xAxisTickFormat={xAxisTickFormat}
                      xAxisHoverFormat={xAxisHoverFormat}
                      xAxisA11yDateFormat={xAxisA11yDateFormat}
                      graphStartTime={metricGraphStartTime}
                      graphEndTime={metricGraphEndTime}
                      consumerName={consumerNameById?.[result.consumer_id]}
                    />
                  </Fragment>
                ))
              ))
            }
          </Suspense>
        )
      }
    </Grid>
  );
}

export default memo(ServiceProviderMetrics);
