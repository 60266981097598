import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getCompanyDetail, getServiceDetail, getSubscriptionDetail } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { selectCompanyId } from '../app/slices/user';
import MajorHeading from '../components/common/MajorHeading';
import ServiceHeader from '../components/service-detail/ServiceHeader';
import ServiceMetrics from '../components/service-detail/ServiceMetrics';
import GraphTimeRangeSelect from '../components/service-metric/GraphTimeRangeSelect';
import GraphUnitSelect from '../components/service-metric/GraphUnitSelect';
import ServiceProviderMetrics from "../components/service-detail/ServiceProviderMetrics";
import { useAbortController } from '../hooks';
import { TIME_RANGE_FILTER_OPTIONS,
         UNIT_FILTER_OPTIONS } from '../utils/serviceMetrics';
import { findCurrentVersion, getServiceLogoDataUrl } from '../utils/serviceDetail';
import { ServiceTypeUtils } from '../utils/utils';


/**
 * This provides the display of the Quality Of Service graphs for a service.
 * This page is displayed when viewing the service detail page for a service
 * then scrolling down to is Versions (opening it if necessary) and clicking
 * Graphs.  This Quality of Service page expects "serviceId" and "providerId"
 * key and value pairs as query string parameters.
 */

const QualityOfService = () => {

  const { abortSignalRef, isCancel } = useAbortController();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [service, setService] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribedToCurrent, setIsSubscribedToCurrent] = useState(false);
  const [selectedTimeRangeFilter, setSelectedTimeRangeFilter] = useState(TIME_RANGE_FILTER_OPTIONS[3]);
  const [selectedUnitFilter, setSelectedUnitFilter] = useState(UNIT_FILTER_OPTIONS[1]);

  const companyId = useSelector(selectCompanyId); // consumer's company ID

  const serviceId = searchParams.get('serviceId');
  const providerId = searchParams.get('providerId');
  const versionId = searchParams.get('versionId');
  const serviceType = searchParams.get('serviceType');

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(setPageTitle('Quality of Service'));

    // fetch the detailed service info
    getServiceDetail({
      service_id: serviceId,
      consumer_id: companyId,
      version_status: 'launched',
      abortSignal: abortSignalRef?.current
    })
    .then(async (res) => {
        if (res?.data) {
          const service = res.data;
          dispatch(setPageTitle('Quality of Service: ' + service.name));
          service['service_logo_data_url'] = await getServiceLogoDataUrl(service);
          setService(service);
          const currentVersion = findCurrentVersion(service.versions);
          setIsSubscribedToCurrent(!!currentVersion?.subscribed);
        }
    })
    .catch(err => {
        if (isCancel(err))
          return;

        console.error(`failed to get service details for service ${serviceId}:`, err.message);
        dispatch(setAlert({
          show: true,
          message: 'Failed to get service details',
          severity: 'error'
        }));
    });

    getCompanyDetail({ id: providerId, abortSignal: abortSignalRef?.current })
    .then(res => {
        if (res['data']) {
          setProvider(res['data']);
        }
    })
    .catch(err => {
        if (isCancel(err))
          return;

        console.error(`failed to get provider details for provider ${providerId}:`, err.message);
        dispatch(setAlert({
          show: true,
          message: 'Failed to get provider details',
          severity: 'error'
        }));
    });

    getSubscriptionDetail({
      company_id: companyId, service_id: serviceId, version_id: versionId, abortSignal: abortSignalRef?.current
    })
      .then(res => {
        setIsSubscribed(!!res.data && Object.keys(res.data).length > 0);
      })
      .catch(err => {
          if (isCancel(err))
            return;

          console.error(`failed to get subscription detail for ` +
            `companyId: ${companyId}, serviceId: ${serviceId}, versionId: ${versionId}`, err);
          dispatch(setAlert({
            show: true,
            message: 'Failed to get subscription detail',
            severity: 'error'
          }));
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeRangeFilterSelect = (newTimeRange) => {
    setSelectedTimeRangeFilter(newTimeRange);
  };

  const handleUnitFilterSelect = (newSelectedUnit) => {
    setSelectedUnitFilter(newSelectedUnit);
  };

  return (
      <Fragment>
          <ServiceHeader
              service={service}
              provider={provider}
              isSubscribed={isSubscribed}
              isSubscribedToCurrentVersion={isSubscribedToCurrent}
          />

          <Grid container sx={{ my: "1rem" }} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                  <MajorHeading label="QoS Metrics Across Consumers and across APIs" />
              </Grid>
              <Grid item xs={6} sm={4}>
                  <Paper>
                      <GraphTimeRangeSelect
                          handleTimeRangeSelect={handleTimeRangeFilterSelect}
                          selectedTimeRange={selectedTimeRangeFilter}
                          size="small"
                      />
                  </Paper>
              </Grid>
              <Grid item xs={6} sm={3}>
                  <Paper>
                      <GraphUnitSelect
                          handleUnitSelect={handleUnitFilterSelect}
                          selectedUnit={selectedUnitFilter}
                          supportedUnitIds={
                              selectedTimeRangeFilter.supportedUnitIds
                          }
                          size="small"
                      />
                  </Paper>
              </Grid>

              <Grid container item rowSpacing={2}>
                  {
                      // Display the ServiceMetrics component only for REST services
                      serviceType === ServiceTypeUtils.TYPES.REST && (
                        <ServiceMetrics
                          serviceId={serviceId}
                          versionId={versionId}
                          metricTimeRange={selectedTimeRangeFilter}
                          metricUnit={selectedUnitFilter}
                        />
                      )
                  }

                  <ServiceProviderMetrics
                      serviceId={serviceId}
                      versionId={versionId}
                      serviceType={serviceType}
                      metricTimeRange={selectedTimeRangeFilter}
                      metricUnit={selectedUnitFilter}
                  />
              </Grid>
          </Grid>
      </Fragment>
  );
};

export default QualityOfService;
